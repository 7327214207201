// Variable overrides
$_bgColor: #f1f4f6;
$_brandBlue: #061a5f;
$_brandPink: #d9177a;

$theme-colors: (
  'primary': #06195f,
  'cyan': #06195f36,
  'blue': #06195f36,
  'light-blue': #06195f36,
  'info': #06195f36
);
